export default {
    dark: true,
    colors: {
        "background": '#121212',           // Dark background
        "surface": '#1e1e1e',              // Slightly lighter surface for contrast
        'surface-bright': '#121212',       // Dark surface for highlights
        'surface-light': '#333333',        // Darker but distinguishable surface
        'surface-variant': '#b2bfc9',      // Muted variant surface in dark mode
        'on-surface-variant': '#FFFFFF',   // White text for contrast on dark surfaces
        "primary-lighten-2": "#4ba9e1",    // Soft blue lighten 2
        "primary-lighten-1": '#2b8ac4',    // Soft blue lighten 1
        "primary": '#18638a',              // Main soft blue color (darker)
        "primary-darken-1": '#0d4563',     // Darker blue for primary dark
        'primary-darken-2': '#092f45',     // Even darker blue
        "secondary-lighten-2": "#f48942",  // Light orange for secondary
        "secondary-lighten-1": '#d57332',  // Slightly darker orange
        "secondary": '#ad5c25',            // Main orange tone
        'secondary-darken-1': '#8a4419',   // Darker orange
        "secondary-darken-2": '#5e2e0f',   // Even darker orange
        "error": '#B00020',                // Error red remains the same
        "info": '#2196F3',                 // Blue for info
        "success": '#4CAF50',              // Green for success
        "warning": '#FB8C00',              // Orange for warning
    }
}
