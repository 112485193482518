export default {
    dark: false,
    colors: {
        "background": '#f2f7fb',           // Soft light blue for background
        "surface": '#e6eef5',              // Lighter version for surfaces
        'surface-bright': '#d9e5f2',       // Even lighter surface
        'surface-light': '#c4c4c4',        // Neutral light surface
        'surface-variant': '#b2bfc9',      // Muted variant surface
        'on-surface-variant': '#000000',   // Black text on surface variants
        "primary-lighten-2": "#b3d9f2",    // Very soft blue (lighten)
        "primary-lighten-1": '#80c1ea',    // Lighter soft blue
        "primary": '#4ba9e1',              // Main soft blue color
        "primary-darken-1": "#2b8ac4",     // Darker soft blue
        'primary-darken-2': '#18638a',     // Even darker blue
        "secondary-lighten-2": "#f9e0cc",  // Light orange for secondary
        "secondary-lighten-1": '#f7b589',  // Lighter orange
        "secondary": '#f48942',            // Main soft orange color
        'secondary-darken-1': '#d57332',   // Darker orange
        "secondary-darken-2": '#ad5c25',   // Even darker orange
        "error": '#d40000',                // Error remains red
        "info": '#0059A6',                 // Deep blue for information
        "success": '#039600',              // Green for success
        "warning": '#ff6200',              // Bright orange for warnings
    }
}