<template>
    <v-layout>
        <v-main class="d-flex align-center justify-center">
            <router-view style="z-index: 1"></router-view>
            <v-fab
                icon="mdi-theme-light-dark"
                :size="$mobile ? 56 : 70"
                style="position: fixed; bottom: 5px;"
                app
                appear
                @click="toogleTheme"
            ></v-fab>
            <v-fab
                icon="mdi-instagram"
                :size="$mobile ? 56 : 70"
                :style="$mobile ? 'position: fixed; bottom: 5px; left: -60px;' : 'position: fixed; bottom: 5px; left: -80px;'"
                app
                href="https://www.instagram.com/785pilates.studio"
                target="_blank"
            ></v-fab>
            <v-fab
                icon="mdi-gmail"
                :size="$mobile ? 56 : 70"
                :style="$mobile ? 'position: fixed; bottom: 5px; left: -120px;' : 'position: fixed; bottom: 5px; left: -160px;'"
                app
                :href="$mobile ? 'mailto:785studiopilates@gmail.com' : 'https://mail.google.com/mail/?view=cm&fs=1&to=785studiopilates@gmail.com&su=Consulta&body=Buenos%20d%C3%ADas'"
                target="_blank"
            ></v-fab>
            <v-fab
                icon="mdi-whatsapp"
                :size="$mobile ? 56 : 70"
                :style="$mobile ? 'position: fixed; bottom: 5px; left: -180px;' : 'position: fixed; bottom: 5px; left: -240px;'"
                app
                href="https://wa.me/613548662"
                target="_blank"
            ></v-fab>
            <v-fab
                icon="mdi-phone"
                :size="$mobile ? 56 : 70"
                :style="$mobile ? 'position: fixed; bottom: 5px; left: -240px;' : 'position: fixed; bottom: 5px; left: -320px;'"
                app
                href="tel:613548662"
                target="_blank"
            ></v-fab>
        </v-main>
    </v-layout>
</template>

<script>
import { useTheme } from 'vuetify'

export default {
    setup() {
        const theme = useTheme()
        const toogleTheme = () => {
            localStorage.setItem('theme', theme.global.current.value.dark ? 'light' : 'dark')
            theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
        }
        return { toogleTheme }
    }
}
</script>

<style scoped>
main {
    margin-bottom: -250px;
}

</style>