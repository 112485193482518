<template>
    <div>
        <v-app-bar flat app color="primary" height="50">
            <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>
                {{ items.find(item => $route.name === item.to)?.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                @click="logout"
                :to="{ name: 'login' }"
                color="error"
            >
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            app
        >
            <v-list-item
                class="d-flex justify-center mt-4"
            >
                <v-avatar size="64">
                    <v-icon size="64" color="secondary">
                        mdi-account-circle
                    </v-icon>
                </v-avatar>
            </v-list-item>
            <v-list-item
                class="d-flex justify-center"
            >
                <v-list-item-title>
                    {{ getUserInfo?.username }}
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list density="compact" nav>
                <v-list-item
                    v-for="(item, i) in items.filter(item => hasAccess(item.roles, item.groups))"
                    :key="i"
                    :prepend-icon="item.icon"
                    :title="item.title"
                    link
                    :to="{ name: item.to }"
                    active-class="text-primary"
                    class="px-8"
                    base-color="primary"
                    color="secondary"
                ></v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list-item
                    prepend-icon="mdi-logout"
                    title="Logout"
                    value="logout"
                    link
                    :to="{ name: 'login' }"
                    @click="logout"
                    class="d-flex justify-center mb-4 text-error"
                >
                </v-list-item>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import { uselogin } from '@/stores/login'
import { mapActions, mapState } from 'pinia'
import { ADMIN, USER } from '@/util'

export default {
    setup() {
        const { hasAdminPermissions } = uselogin()
        const internalInstance = getCurrentInstance()
        const $mobile = internalInstance.appContext.config.globalProperties.$mobile

        const drawer = ref($mobile ? false : true)
        const items = [
            {
                icon: 'mdi-account-file-text-outline',
                title: hasAdminPermissions ? 'Perfil de Cliente' : 'Mi Perfil',
                value: 'profile',
                to: 'profile',
                groups: [],
                roles: []
            },
            { 
                icon: 'mdi-calendar-multiselect', 
                title: 'Horarios',
                value: 'home', 
                to: 'home',
                groups: [],
                roles: []
            },
            { 
                icon: 'mdi-google-classroom', 
                title: 'Clases',
                value: 'lessons', 
                to: 'lessons',
                groups: [],
                roles: [ADMIN]
            },
            { 
                icon: 'mdi-security', 
                title: 'Administración',
                value: 'administration', 
                to: 'administration',
                groups: [],
                roles: [ADMIN]
            },
            { 
                icon: 'mdi-cash-sync', 
                title: 'Pagos',
                value: 'payment', 
                to: 'payment',
                groups: [],
                roles: [USER]
            },
        ]

        return { drawer, items }
    },
    methods: {
        ...mapActions(uselogin, ['logout', 'hasAccess'])
    },
    computed: {
        ...mapState(uselogin, ['getUserInfo'])
    }
}
</script>